<template>
  <div id="quotation">
    <app-drawer_menu :drawer_menu_title="form.title"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <v-layout wrap v-if="formwizard.step === 1 && access.read === 1">
      <v-stepper v-model="pp_la" vertical>
        <v-stepper-step :complete="pp_la > 1" step="1"> Start </v-stepper-step>
        <v-stepper-content step="1">
          <v-card outlined>
            <v-form
              enctype="multipart/form-data"
              ref="form_campaign"
              lazy-validation
            >
              <v-card-title>Campaign</v-card-title>
              <v-card-subtitle>Select Campaign Template</v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="4" md="4" class="mt-n3">
                    <v-text-field
                      v-model="form.ticket_id"
                      required
                      label="ID"
                      placeholder="Automated ID"
                      id="ticket_id"
                      prepend-inner-icon="mdi-barcode-scan"
                      background-color="readonly"
                      :readonly="true"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" sm="2" md="2" class="mt-n3">
                    <v-dialog
                      ref="ticket_date"
                      v-model="dialog.ticket_date"
                      :return-value.sync="form.ticket_date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="form.ticket_date"
                          label="Transaction Date"
                          prepend-inner-icon="event"
                          background-color="date"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="form.ticket_date" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="dialog.ticket_date = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.ticket_date.save(form.ticket_date)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <app-cb
                      cb_type="campaign"
                      cb_url="apiListCampaign"
                      cb_url_parameter="campaig_actived=Y"
                      cb_title="Campaign"
                      cb_id="ticket_campaignid"
                      cb_desc="ticket_campaigndesc"
                      cb_rules="Please fill Campaign ID"
                      cb_desc_readonly="readonly"
                      cb_items_id="campaign_id"
                      cb_items_desc="campaign_desc"
                      cb_items_additional_1="campaign_subclass"
                      :cb_value_id="form.ticket_campaignid"
                      :cb_value_desc="form.ticket_campaigndesc"
                      @clicked="GetCampaign"
                    >
                    </app-cb>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <app-cb
                      cb_type="cedant"
                      cb_url="apiListProfile"
                      cb_url_parameter="profile_actived=Y"
                      cb_title="Cedant"
                      cb_id="profile_id"
                      cb_desc="profile_name"
                      cb_rules="Please fill Cedant ID"
                      cb_desc_readonly="readonly"
                      cb_items_id="profile_id"
                      cb_items_desc="profile_name"
                      :cb_value_id="form.ticket_profileid"
                      :cb_value_desc="form.ticket_profiledesc"
                      @clicked="GetCedant"
                    >
                    </app-cb>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <app-cb
                      cb_type="policyholder"
                      cb_url="apiListProfile"
                      cb_url_parameter="profile_actived=Y&profile_pholder=Y"
                      cb_title="Policy Holder"
                      cb_id="profile_id"
                      cb_desc="profile_name"
                      cb_rules="Please fill Policy Holder ID"
                      cb_desc_readonly="readonly"
                      cb_items_id="profile_id"
                      cb_items_desc="profile_name"
                      :cb_value_id="form.ticket_pholderid"
                      :cb_value_desc="form.ticket_pholdername"
                      @clicked="GetPholder"
                    >
                    </app-cb>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <app-cb
                      cb_type="leader"
                      cb_url="apiListUsers"
                      cb_url_parameter="users_actived=Y&users_employeef=Y"
                      cb_title="Marketing"
                      cb_id="ticket_mo"
                      cb_desc="Marketing Name"
                      cb_rules="Please fill Marketing"
                      cb_desc_readonly="readonly"
                      cb_items_id="users_id"
                      cb_items_desc="users_name"
                      :cb_value_id="form.ticket_mo"
                      :cb_value_desc="form.ticket_modesc"
                      @clicked="GetMarketing"
                    >
                    </app-cb>
                  </v-col>
                  <v-col cols="4" sm="4" md="4" class="mt-n3">
                    <app-cb
                      cb_type="currency"
                      cb_url="apiListCurrency"
                      cb_url_parameter="currency_actived=Y"
                      cb_title="In CCY"
                      cb_id="profileporto_ccy"
                      cb_desc="profileporto_ccydesc"
                      cb_rules=""
                      cb_desc_readonly="readonly"
                      cb_items_id="currency_id"
                      cb_items_desc="currency_desc"
                      :cb_value_id="form.ticket_ccy"
                      :cb_value_desc="form.ticket_ccydesc"
                      cb_single="Y"
                      @clicked="GetCCY"
                    >
                    </app-cb>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="mt-n6">
                    <vuetify-money
                      v-model="form.ticket_tsi"
                      v-bind:options="format_rate"
                      label="TSI"
                      placeholder="TSI"
                      background-color="numeric"
                      v-bind:properties="properties"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="Confirm('writestep1', '')"
                  >Confirm</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </v-stepper-content>
        <v-stepper-step :complete="pp_la > 2" step="2">
          Reinsurance
          <small></small>
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-row>
            <v-col cols="12" md="9">
              <v-card outlined>
                <v-card-title>Select Reinsuranse</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="3" md="3" class="mt-n3">
                      <v-text-field
                        v-model="form.ticket_id"
                        required
                        label="ID"
                        placeholder="Automated ID"
                        id="ticket_id_detail"
                        prepend-inner-icon="mdi-barcode-scan"
                        background-color="readonly"
                        :readonly="true"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6" sm="2" md="2" class="mt-n3">
                      <v-text-field
                        v-model="form.ticket_date"
                        label="Transaction Date"
                        prepend-inner-icon="event"
                        background-color="readonly"
                        :readonly="true"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="7" md="7" class="mt-n3">
                      <v-row>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            v-model="form.ticket_campaignid"
                            label="Campaign ID"
                            prepend-inner-icon="list"
                            background-color="readonly"
                            :readonly="true"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="8" md="8">
                          <v-text-field
                            v-model="form.ticket_campaigndesc"
                            label="Campaign"
                            background-color="readonly"
                            :readonly="true"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="mt-n3">
                      <v-row>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            v-model="form.ticket_profileid"
                            label="Cedant ID"
                            prepend-inner-icon="list"
                            background-color="readonly"
                            :readonly="true"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="8" md="8">
                          <v-text-field
                            v-model="form.ticket_profiledesc"
                            label="Cedant"
                            background-color="readonly"
                            :readonly="true"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="mt-n3">
                      <v-row>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            v-model="form.ticket_mo"
                            label="Marketing ID"
                            prepend-inner-icon="list"
                            background-color="readonly"
                            :readonly="true"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="8" md="8">
                          <v-text-field
                            v-model="form.ticket_modesc"
                            label="Marketing"
                            background-color="readonly"
                            :readonly="true"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="mt-n3">
                      <v-text-field
                        v-model="form.ticket_ccy"
                        label="CCY"
                        prepend-inner-icon="list"
                        background-color="readonly"
                        :readonly="true"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="mt-n3">
                      <v-text-field
                        ref="ticket_tsi"
                        label="TSI"
                        placeholder="0"
                        background-color="readonly"
                        id="ticket_tsi"
                        reverse
                        type="text"
                        :value="
                          this.$functions.NewFormatNumber(form.ticket_tsi)
                        "
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card class="mb-12" outlined>
                <v-card-text>
                  <v-alert
                    border="top"
                    colored-border
                    type="info"
                    elevation="2"
                  >
                    {{ form.ticket_keydesc }}
                  </v-alert>

                  <v-btn
                    class="mb-2"
                    color="fourth"
                    title="Binding"
                    @click="Approval('approve')"
                    outlined
                    block
                    :disabled="form.ticket_key === 'N' ? false : true"
                    >Binding
                  </v-btn>
                  <v-btn
                    class="mb-2"
                    color="fourth"
                    title="Loss Business"
                    outlined
                    block
                    @click="Approval('reject')"
                    >Loss Business
                  </v-btn>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        >Timeline</v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <template>
                          <v-timeline dense clipped>
                            <v-timeline-item
                              class="mb-4"
                              color="green"
                              icon-color="green lighten-2"
                              small
                            >
                              <v-row justify="space-between">
                                <v-col cols="7">
                                  Created
                                  <br />
                                  <v-chip
                                    class="white--text ml-0"
                                    color="info"
                                    label
                                    small
                                  >
                                    {{ this.form.ticket_cuser }}
                                  </v-chip>
                                </v-col>
                                <v-col class="text-right" cols="5">
                                  {{
                                    this.$functions.FormatDateTime(
                                      this.form.ticket_cdate
                                    )
                                  }}
                                </v-col>
                              </v-row>
                            </v-timeline-item>
                            <v-timeline-item
                              class="mb-4"
                              color="green"
                              icon-color="green lighten-2"
                              small
                            >
                              <v-row justify="space-between">
                                <v-col cols="7">
                                  Updated
                                  <br />
                                  <v-chip
                                    class="white--text ml-0"
                                    color="info"
                                    label
                                    small
                                  >
                                    {{ this.form.ticket_luser }}
                                  </v-chip>
                                </v-col>
                                <v-col class="text-right" cols="5">
                                  {{
                                    this.$functions.FormatDateTime(
                                      this.form.ticket_ldate
                                    )
                                  }}
                                </v-col>
                              </v-row>
                            </v-timeline-item>
                          </v-timeline>
                        </template>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-tabs
                v-model="tabs"
                background-color="white"
                color="secondary"
                next-icon="mdi-arrow-right-bold-box-outline"
                prev-icon="mdi-arrow-left-bold-box-outline"
                show-arrows
              >
                <v-tab id="tabreinsurance" href="#tab-reinsurance">
                  Reinsurance
                </v-tab>
                <v-tab id="attachment" href="#tab-attachment">
                  Attachment
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabs">
                <v-tab-item value="tab-reinsurance">
                  <app-reinsurance
                    :value="true"
                    :id="form.ticket_id"
                    :subclass="form.ticket_subclass"
                  />
                </v-tab-item>
                <v-tab-item value="tab-attachment">
                  <app-attachmentreins
                    modul="reinsattach"
                    :value="true"
                    :refno="form.ticket_id"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper>
    </v-layout>
    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="success"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
            >{{ confirm.text }}
          </v-btn>
          <v-btn color="error" @click="confirm.dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog.approval"
      max-width="1200"
      transition="dialog-transition"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="dialog.approval = false">
            <v-icon color="yellow">{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>{{ dialog.approvaltitle }}</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn
            color="orange"
            @click="Confirm(url_type, '')"
            title="Save"
            small
            class=""
          >
            Process</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-form
            enctype="multipart/form-data"
            ref="form_approval"
            lazy-validation
          >
            <v-row class="mx-auto">
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="form.ticket_picname"
                  label="PIC"
                  required
                  :rules="form.ticket_pic_rules"
                  placeholder="PIC"
                />
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-dialog
                  ref="ticket_adate"
                  v-model="dialog.ticket_adate"
                  :return-value.sync="form.ticket_adate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="form.ticket_adate"
                      label="Date"
                      prepend-inner-icon="event"
                      background-color="date"
                      v-on="on"
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.ticket_adate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="dialog.ticket_adate = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.ticket_adate.save(form.ticket_adate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <vuetify-money
                  v-model="form.reinstrans_share"
                  :options="formatamount15"
                  label="Commission Cedant"
                  placeholder="Commission Cedant"
                  :properties="properties"
                />
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mt-n3">
                <app-cb
                  cb_type="code"
                  cb_url="apiListCode"
                  :cb_url_parameter="form.reason_params"
                  cb_title="Reason"
                  cb_id="ticket_reason"
                  cb_desc="ticket_reasondesc"
                  cb_rules="Please fill Reason"
                  cb_desc_readonly="readonly"
                  cb_items_id="code_id"
                  cb_items_desc="code_desc"
                  :cb_value_id="form.ticket_reason"
                  :cb_value_desc="form.ticket_reasondesc"
                  required
                >
                </app-cb>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mt-n3">
                <label class="caption">Remarks</label>
                <quill-editor
                  ref="ticket_remarks"
                  v-model="form.ticket_remarks"
                  required
                >
                </quill-editor>
              </v-col>
              <app-reinsurance-binding
                :id="form.ticket_id"
                :subclass="form.ticket_subclass"
              ></app-reinsurance-binding>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
.custom-card {
  background: linear-gradient(
    126deg,
    rgb(33 150 243 / 37%) 50%,
    #2196f3 48%,
    rgb(229 233 237) 49%
  );
}
.hover-card:hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transform: scale(1.03);
  box-shadow: 1px 3px 4px rgb(255 136 0) !important;
}
.bg-success-100 {
  background-color: rgba(40, 199, 111, 0.1) !important;
}

.text-success-600 {
  color: #28c76f !important;
}

.text-secondary-light {
  color: #6e6b7b !important;
}

.bg-gradient-reject {
  background: linear-gradient(to right, #ffffff, #ffecec);
}

.bg-gradient-request {
  background: linear-gradient(to right, #ffffff, #ffeacd);
}
.bg-gradient-prod {
  background: linear-gradient(to right, #ffffff, #00aff25e);
}

.mb-8 {
  margin-bottom: 2rem;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.75rem;
}
::v-deep .v-timeline-item__divider {
  position: absolute !important;
  left: 0px !important;
  height: 60px;
  min-width: 5px;
}
::v-deep .v-timeline::before {
  left: calc(11px - 1px) !important;
  right: initial;
}
::v-deep .v-timeline-item__body {
  max-width: calc(100% - 36px);
}
.v-timeline {
  font-size: 12px;
  font-weight: bold;
}
</style>
<script>
export default {
  data: () => ({
    url_type: "",
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    formwizard: {
      step: 1,
    },
    quotationsearch: {
      dialog: false,
      status: "",
      statusdesc: "",
      title: "",
    },
    gpioverlay: true,
    pp_la: 1,
    dialog: {
      policy: false,
      ticket_date: false,
      approval: false,
      ticket_adate: false,
    },
    // Format Policy Amount
    format_amount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0,
    },
    format_rate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 2,
    },
    formatamount15: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 18,
      precision: 15,
    },
    pp_stepper: 1,
    properties: {
      reverse: true,
    },
    form: {
      title: "Quotation",
      id: "",
      ticket_id: "",
      ticket_date: "",
      ticket_status: "",
      ticket_key: "",
      ticket_keydesc: "",
      ticket_campaignid: "",
      ticket_campaigndesc: "",
      ticket_subclass: "",
      ticket_profileid: "",
      ticket_profiledesc: "",
      ticket_mo: "",
      ticket_modesc: "",
      ticket_pholderid: "",
      ticket_pholdername: "",
      ticket_adate: "",
      ticket_reason: "",
      ticket_reasondesc: "",
      ticket_remarks: "",
      reason_params: "",
      ticket_ccy: "",
      ticket_ccydesc: "",
      ticket_tsi: "",
      ticket_cdate: "",
      ticket_cuser: "",
      ticket_ldate: "",
      ticket_luser: "",
      ticket_pic_rules: [(v) => !!v || "Please fill PIC"],
      ticket_commprofile: 0,
      users_otherid: "",
      users_type: "",
      users_marketingtype: "",
    },
    slider: 0,
    loading: {
      page: false,
    },
    widgets: false,
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
      send: false,
      sendtitle: "",
      sendtext: "Ok",
    },
    remarks: {
      feedback: "",
      dialog: false,
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    tabs: null,
    dropzoneOptions: {
      url: "/",
      thumbnailWidth: 200,
      autoProcessQueue: false,
      uploadMultiple: true,
      maxFiles: 10,
      addRemoveLinks: true,
      acceptedFiles: ".xls,.xlsx,.doc,.docx,.pdf,.jpeg,.jpg,.png,.eml",
    },
  }),
  watch: {
    "$route.params.id": {
      handler: function (value) {
        if (value) {
          this.Edit(value);
        } else {
          this.resetForm(); // Panggil method untuk reset form
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.loading.page = true;
    this.FirstLoad();
    this.form.ticket_date = this.$functions.TodayYYYYMMDD();
    this.form.ticket_adate = this.$functions.TodayYYYYMMDD();
    this.form.ticket_ccy = "IDR";
    this.form.ticket_mo = this.$functions.UsersID();
    this.form.ticket_modesc = this.$functions.UsersName();
    this.form.users_otherid = this.$functions.UsersOthersID();
    this.form.users_type = this.$functions.UsersType();
    this.form.users_marketingtype = this.$functions.UsersMarketingType();
  },
  methods: {
    FirstLoad() {
      //this function use for validating the screen with user privilleges
      this.Access();
    },
    //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
    Access() {
      let modul = "quotation";
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data;
          if (priv.length > 0) {
            this.access.read = priv[0].usersmenu_read;
            this.access.add = priv[0].usersmenu_add;
            this.access.edit = priv[0].usersmenu_edit;
            this.access.delete = priv[0].usersmenu_delete;
            if (priv[0].usersmenu_read === 0) {
              this.SnackBar(
                true,
                "error",
                this.$functions.NoPriviledge(modul),
                0
              );
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            );
          }
          this.loading.page = false;
        })
        .catch((e) => {
          console.log(e);
          this.SnackBar(true, "error", e, 0);
          this.loading.page = false;
        });
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    GetSearch(value) {
      if (this.quotationsearch.title === "quotation") {
        this.$router
          .push({
            name: "quotationdetail",
            params: { id: this.$functions.EncodeUrl(value) },
          })
          .catch(() => {});
      }
    },
    GetListCode(value) {
      console.log("GET LIST CODE");
      let formdata = {
        code_group: value,
        order_by: "code_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCode"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              if (value === "RATEUNIT") {
                this.policyrisk_rateunit = feedback;
              } else if (value === "POLICYCATEGORY") {
                this.policyrisk_category = feedback;
              }
            }
          } else {
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
        });
    },
    TicketStatus(type, desc) {
      this.form.ticket_status = type;
      this.form.title = "Quotation > " + desc;
      // this.Step("1"desc);
    },
    Confirm(flag) {
      if (flag === "writestep1") {
        if (this.$refs.form_campaign.validate()) {
          this.url_type = "writestep1";
          this.confirm.dialog = true;
          this.confirm.title = "Confirm Campaign";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "add") {
        // if (this.$refs.form_campaign.validate()) {
        this.url_type = "add";
        this.confirm.send = true;
        this.confirm.sendtitle = "Send To Reinsurance";
        // }
      } else if (flag === "approve" || flag === "reject") {
        if (this.$refs.form_approval.validate()) {
          this.url_type = flag;
          this.confirm.dialog = true;
          this.confirm.title = flag === "approve" ? "Binding" : "Loss Business";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      }
    },
    //update Approve OR Reject
    Approval(type) {
      this.url_type = type;
      this.dialog.approval = true;
      this.form.ticket_picname = "";
      this.form.ticket_reason = "";
      this.form.ticket_reasondesc = "";
      this.form.ticket_remarks = "";
      this.form.reason_params = `code_group=${type.toUpperCase()}&code_actived=Y`;
      this.dialog.approvaltitle =
        type === "approve" ? "Binding?" : "Loss Business?";
    },
    Loading() {
      this.confirm.text = "Loading...";
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write();
      } else if (this.url_type === "writestep1") {
        // this.formwizard.step = 2;
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
        this.url_type = "add";
        this.Step1();
      } else if (this.url_type === "delete") {
        this.Delete(this.ticket_campaignid);
      } else if (this.url_type === "multidelete") {
        this.MultiProcess("delete");
      } else if (this.url_type === "approve") {
        this.Action(this.url_type);
      }
    },
    Step1() {
      let ticket_type = "Q01";
      let ticket_date = this.$functions.TodayYYYYMMDD();
      let ticket_source = "A";
      let ticket_name = "";
      let ticket_subject =
        "Q#" + this.form.ticket_id + "#" + this.form.ticket_profiledesc;
      let ticket_refno = "NA";
      let ticket_description = "";
      let ticket_remarks = "";
      let ticket_solution = "";
      let ticket_category = "PENAWARAN";
      let ticket_priority = "H";
      let ticket_status = "";
      let ticket_pic = "system";
      let ticket_receiveddate = this.$functions.TodayYYYYMMDD();
      let ticket_targetdate = this.$functions.TodayYYYYMMDD();
      let ticket_key = "N";
      let ticket_amount = 0;
      let ticket_mo = this.form.ticket_mo;
      let ticket_multiyear = "N";
      let ticket_wordingcustom = "N";
      let ticket_paymentmethod = "";
      let ticket_information = "";
      let ticket_rate = 0;
      let ticket_rateunit = "C";
      let ticket_guaranteefee = 0;
      let ticket_share = "0";
      let ticket_extendday = "0";
      let ticket_feeid = "CF";
      let ticket_feeamount = 0;
      let ticket_dutyid = "FD";
      let ticket_dutyamount = 0;
      let ticket_feedesc = "Certificate Fee";
      let ticket_dutydesc = "Fee Duty";
      let ticket_adminrandom = "0";
      let ticket_bookdate = this.$functions.TodayYYYYMMDD();
      let ticket_endorsef = "0";
      let ticket_indemnity = "0";
      let ticket_sourcetax = "0";
      let formdata = {
        url_type: this.url_type,
        ticket_campaignid: this.form.ticket_campaignid,
        ticket_subclass: this.form.ticket_subclass,
        ticket_type: ticket_type,
        ticket_date: ticket_date,
        ticket_source: ticket_source,
        ticket_name: ticket_name,
        ticket_subject: ticket_subject,
        ticket_refno: ticket_refno,
        ticket_description: ticket_description,
        ticket_remarks: ticket_remarks,
        ticket_solution: ticket_solution,
        ticket_category: ticket_category,
        ticket_priority: ticket_priority,
        ticket_status: ticket_status,
        ticket_pic: ticket_pic,
        ticket_receiveddate: ticket_receiveddate,
        ticket_targetdate: ticket_targetdate,
        ticket_key: ticket_key,
        ticket_amount: String(ticket_amount),
        ticket_mo: ticket_mo,
        ticket_profileid: this.form.ticket_profileid,
        ticket_multiyear: ticket_multiyear,
        ticket_wordingcustom: ticket_wordingcustom,
        ticket_paymentmethod: ticket_paymentmethod,
        ticket_information: ticket_information,
        ticket_rate: String(ticket_rate),
        ticket_rateunit: ticket_rateunit,
        ticket_guaranteefee: String(ticket_guaranteefee),
        ticket_share: ticket_share,
        ticket_extendday: ticket_extendday,
        ticket_feeid: ticket_feeid,
        ticket_feeamount: String(ticket_feeamount),
        ticket_dutyid: ticket_dutyid,
        ticket_dutyamount: String(ticket_dutyamount),
        ticket_feedesc: ticket_feedesc,
        ticket_dutydesc: ticket_dutydesc,
        ticket_adminrandom: ticket_adminrandom,
        ticket_bookdate: ticket_bookdate,
        ticket_endorsef: ticket_endorsef,
        ticket_indemnity: ticket_indemnity,
        ticket_sourcetax: ticket_sourcetax,
        ticket_pholderid: this.form.ticket_pholderid,
        ticket_pholdername: this.form.ticket_pholdername,
        ticket_ccy: this.form.ticket_ccy,
        ticket_tsi: this.form.ticket_tsi,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteTicket"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.url_type = "edit";
              this.form.ticket_id = feedback[0].feedback_id;
              this.pp_la = 2;
              this.form.ticket_key = "N";
              this.form.ticket_keydesc = "New";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    Edit(id) {
      if (id !== undefined) {
        this.loading.page = true;
        let ticket_id = this.$functions.DecodeUrl(id);
        let formdata = {
          ticket_id: ticket_id,
          order_by: "ticket_id",
          order_type: "ASC",
          limit: this.limit,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListTicket"), param, {
            headers: {
              "Content-Type": "text/plain; charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback === "Y") {
                this.form.ticket_id = feedback[0].ticket_id;
                this.form.ticket_status = "N";
                this.form.ticket_key = feedback[0].ticket_key;
                // this.formwizard.step = 1;
                this.form.ticket_date = feedback[0].ticket_date;
                this.form.ticket_campaignid = feedback[0].ticket_campaignid;
                this.form.ticket_campaigndesc = feedback[0].ticket_campaigndesc;
                this.form.ticket_profileid = feedback[0].ticket_profileid;
                this.form.ticket_profiledesc = feedback[0].ticket_profiledesc;
                this.form.ticket_mo = feedback[0].ticket_mo;
                this.form.ticket_modesc = feedback[0].ticket_modesc;
                this.form.ticket_channel = feedback[0].ticket_channel;
                this.form.ticket_channeldesc = feedback[0].ticket_channeldesc;
                this.form.ticket_instype = feedback[0].ticket_instype;
                this.form.ticket_instypedesc = feedback[0].ticket_instypedesc;
                this.form.ticket_policytype = feedback[0].ticket_policytype;
                this.form.ticket_policytypedesc =
                  feedback[0].ticket_policytypedesc;
                this.form.ticket_wpc = feedback[0].ticket_wpc;
                this.form.ticket_grace = feedback[0].ticket_grace;
                this.form.ticket_subclass = feedback[0].ticket_subclass;
                this.form.ticket_ccy = feedback[0].ticket_ccy;
                this.form.ticket_tsi = feedback[0].ticket_tsi;
                this.form.ticket_key = feedback[0].ticket_key;
                this.form.ticket_keydesc = feedback[0].ticket_keydesc;
                this.form.ticket_cdate = feedback[0].ticket_cdate;
                this.form.ticket_cuser = feedback[0].ticket_cuser;
                this.form.ticket_ldate = feedback[0].ticket_ldate;
                this.form.ticket_luser = feedback[0].ticket_luser;
                this.pp_la = 2;
                this.loading.page = false;
              } else {
                this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
                this.loading.page = false;
              }
            } else {
              this.SnackBar(true, "error", feedback, 0);
              this.loading.page = false;
            }
          })
          .catch((e) => {
            this.SnackBar(true, "error", e, 0);
            this.loading.page = false;
          });
      }
    },
    resetForm() {
      this.formwizard.step = 1;
      this.pp_la = 1;
      this.form.id = "";
      this.form.ticket_id = "";
      this.form.ticket_date = "";
      this.form.ticket_status = "";
      this.form.ticket_key = "";
      this.form.ticket_keydesc = "";
      this.form.ticket_campaignid = "";
      this.form.ticket_campaigndesc = "";
      this.form.ticket_subclass = "";
      this.form.ticket_profileid = "";
      this.form.ticket_profiledesc = "";
      this.form.ticket_pholderid = "";
      this.form.ticket_pholdername = "";
      this.form.ticket_adate = "";
      this.form.ticket_reason = "";
      this.form.ticket_reasondesc = "";
      this.form.ticket_remarks = "";
      this.form.reason_params = "";
      this.form.ticket_ccy = "";
      this.form.ticket_ccydesc = "";
      this.form.ticket_tsi = "";
      this.form.ticket_cdate = "";
      this.form.ticket_cuser = "";
      this.form.ticket_ldate = "";
      this.form.ticket_luser = "";
    },
    AppendFile(file) {
      this.formresponse.append("file", file);
    },
    GetCampaign(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let campaignid = splits[0];
        let campaign_desc = splits[1];
        let campaign_subclass = splits[3];
        this.form.ticket_campaignid = campaignid;
        this.form.ticket_campaigndesc = campaign_desc;
        this.form.ticket_subclass = campaign_subclass;
      }
    },
    GetCedant(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let profileid = splits[0];
        let profiledesc = splits[1];
        this.form.ticket_profileid = profileid;
        this.form.ticket_profiledesc = profiledesc;
      }
    },
    GetPholder(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let profileid = splits[0];
        let profiledesc = splits[1];
        this.form.ticket_pholderid = profileid;
        this.form.ticket_pholdername = profiledesc;
      }
    },
    GetCCY(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let ticket_ccy = splits[0];
        let ticket_ccydesc = splits[1];
        this.form.ticket_ccy = ticket_ccy;
        this.form.ticket_ccydesc = ticket_ccydesc;
      }
    },
    GetMarketing(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let mo = splits[0];
        let modesc = splits[1];
        this.form.ticket_mo = mo;
        this.form.ticket_modesc = modesc;
      }
    },
    GetChannel(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let channel = splits[0];
        let channeldesc = splits[1];
        this.form.ticket_channel = channel;
        this.form.ticket_channeldesc = channeldesc;
      }
    },
    GetInsType(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let instype = splits[0];
        let instypedesc = splits[1];
        this.form.ticket_instype = instype;
        this.form.ticket_instypedesc = instypedesc;
      }
    },
    GetPolicyType(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let policytype = splits[0];
        let policytypedesc = splits[1];
        this.form.ticket_policytype = policytype;
        this.form.ticket_policytypedesc = policytypedesc;
      }
    },
    Action(value) {
      let formdata = {
        url_type: value,
        ticket_id: this.form.ticket_id,
        ticket_picname: this.form.ticket_picname,
        ticket_adate: this.form.ticket_adate,
        ticket_reason: this.form.ticket_reason,
        ticket_remarks: this.form.ticket_remarks,
        ticket_commprofile: this.form.reinstrans_share,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteTicket"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.dialog.approval = false;
              this.confirm.text = "Ok";
              this.url_type = "edit";
              this.dialog.approval = true;
              this.$router
                .push({
                  name: "policydetail",
                  params: {
                    id: this.$functions.EncodeUrl(feedback[0].feedback_id),
                  },
                })
                .catch(() => {});
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.dialog.approval = true;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    Formatnumber(value) {
      if (!value) return true;
      return (
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value) === value
      );
    },
  },
};
</script>
