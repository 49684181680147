<template>
  <div id="quotation">
    <app-drawer_menu :drawer_menu_title="form.title"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <app-quotation-search
      :dialog="quotationsearch.dialog"
      :search_title="quotationsearch.title"
      :status="quotationsearch.status"
      :statusdesc="quotationsearch.statusdesc"
      :marketingtype="quotationsearch.marketingtype"
      @close="quotationsearch.dialog = false"
      @clicked="GetSearch"
    >
    </app-quotation-search>
    <v-layout wrap>
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6" md="3">
            <v-card
              class="pa-4 rounded-lg h-100 bg-gradient-prod hover-card"
              elevation="2"
              @click="Inquiry('Q')"
            >
              <v-card-text class="pa-0">
                <div
                  class="d-flex flex-wrap align-items-center justify-space-between gap-1 mb-4"
                >
                  <div class="d-flex align-items-center gap-2">
                    <!-- Icon Circle -->
                    <v-avatar color="rgb(200 238 252)" size="42">
                      <v-icon color="blue" size="28"
                        >mdi-file-document-outline</v-icon
                      >
                    </v-avatar>
                    <div>
                      <h2 style="color: #111827" class="font-weight-bold">
                        {{ "Total " + total.quotation + " Data" }}
                      </h2>
                      <span
                        class="text-secondary-light text-body-2 font-weight-medium"
                      >
                        Quotation
                      </span>
                    </div>
                  </div>
                </div>
                <div class="text-body-2">
                  <span color="#f443365c">{{ total.quotation }}</span> Data
                  Quotation
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-card
              class="pa-4 rounded-lg h-100 bg-gradient-request hover-card"
              elevation="2"
              @click="Inquiry('B')"
            >
              <v-card-text class="pa-0">
                <div
                  class="d-flex flex-wrap align-items-center justify-space-between gap-1 mb-4"
                >
                  <div class="d-flex align-items-center gap-2">
                    <!-- Icon Circle -->
                    <v-avatar size="42" color="#ff980030" class="flex-shrink-0">
                      <v-icon size="28" color="orange"
                        >mdi-scale-balance</v-icon
                      >
                    </v-avatar>
                    <div>
                      <h2 style="color: #111827" class="font-weight-bold">
                        {{ "Total " + total.binding + " Data" }}
                      </h2>
                      <span
                        class="text-secondary-light text-body-2 font-weight-medium"
                      >
                        Binding
                      </span>
                    </div>
                  </div>
                </div>
                <div class="text-body-2">
                  <span color="#f443365c">{{ total.binding }}</span> Data
                  Binding
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Reject Card -->
          <v-col cols="12" sm="6" md="3">
            <v-card
              class="pa-4 rounded-lg h-100 bg-gradient-reject hover-card"
              elevation="2"
              @click="Inquiry('L')"
            >
              <v-card-text class="pa-0">
                <div
                  class="d-flex flex-wrap align-items-center justify-space-between gap-1 mb-4"
                >
                  <div class="d-flex align-items-center gap-2">
                    <!-- Icon Circle -->
                    <v-avatar size="42" color="#f443365c" class="flex-shrink-0">
                      <v-icon color="red">mdi-emoticon-sad </v-icon>
                    </v-avatar>
                    <div>
                      <h2 style="color: #111827" class="font-weight-bold">
                        {{ "Total " + total.lossdata + " Data" }}
                      </h2>
                      <span
                        class="text-secondary-light text-body-2 font-weight-medium"
                      >
                        Loss Business
                      </span>
                    </div>
                  </div>
                </div>
                <div class="text-body-2">
                  <span color="#f443365c">{{ total.lossdata }}</span> Data Loss
                  Business
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>

    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
.custom-card {
  background: linear-gradient(
    126deg,
    rgb(33 150 243 / 37%) 50%,
    #2196f3 48%,
    rgb(229 233 237) 49%
  );
}
.hover-card:hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transform: scale(1.03);
  box-shadow: 1px 3px 4px rgb(255 136 0) !important;
}
.bg-success-100 {
  background-color: rgba(40, 199, 111, 0.1) !important;
}

.text-success-600 {
  color: #28c76f !important;
}

.text-secondary-light {
  color: #6e6b7b !important;
}

.bg-gradient-reject {
  background: linear-gradient(to right, #ffffff, #ffecec);
}

.bg-gradient-request {
  background: linear-gradient(to right, #ffffff, #ffeacd);
}
.bg-gradient-prod {
  background: linear-gradient(to right, #ffffff, #00aff25e);
}

.mb-8 {
  margin-bottom: 2rem;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.75rem;
}
</style>
<script>
export default {
  data: () => ({
    url_type: "",
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },

    quotationsearch: {
      dialog: false,
      status: "",
      statusdesc: "",
      marketingtype: "",
      title: "",
    },

    // Format Policy Amount
    format_amount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0,
    },
    format_rate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 2,
    },
    formatamount15: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 18,
      precision: 15,
    },

    total: {
      quotation: 0,
      quotation_amount: 0,
      binding: 0,
      binding_amount: 0,
      lossdata: 0,
      lossdata_amount: 0,
    },
    form: {
      title: "Quotation",
      users_otherid: "",
      users_type: "",
      users_maketingtype: "",
    },

    loading: {
      page: false,
    },

    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
  }),

  created() {
    this.FirstLoad();
    this.form.users_otherid = this.$functions.UsersOthersID();
    this.form.users_type = this.$functions.UsersType();
    this.form.users_maketingtype = this.$functions.UsersMarketingType();
    this.quotationsearch.marketingtype = this.$functions.UsersMarketingType();
    this.GetTotalData();
  },
  methods: {
    FirstLoad() {
      //this function use for validating the screen with user privilleges
      this.Access();
    },
    //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
    Access() {
      let modul = "quotation";
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data;
          if (priv.length > 0) {
            this.access.read = priv[0].usersmenu_read;
            this.access.add = priv[0].usersmenu_add;
            this.access.edit = priv[0].usersmenu_edit;
            this.access.delete = priv[0].usersmenu_delete;
            if (priv[0].usersmenu_read === 0) {
              this.SnackBar(
                true,
                "error",
                this.$functions.NoPriviledge(modul),
                0
              );
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            );
          }
          this.loading.page = false;
        })
        .catch((e) => {
          console.log(e);
          this.SnackBar(true, "error", e, 0);
          this.loading.page = false;
        });
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    GetSearch(value) {
      if (this.quotationsearch.title === "quotation") {
        this.$router
          .push({
            name: "quotationdetail",
            params: { id: this.$functions.EncodeUrl(value) },
          })
          .catch(() => {});
      }
    },
    Inquiry(value) {
      this.quotationsearch.dialog = true;
      this.quotationsearch.title = "quotation";
      if (value == "Q") {
        this.quotationsearch.statusdesc = "Quotation";
      } else if (value == "B") {
        this.quotationsearch.statusdesc = "Binding";
      } else if (value == "L") {
        this.quotationsearch.statusdesc = "Loss Business";
      }
      this.quotationsearch.status = value;
    },
    GetTotalData() {
      let formdata = {
        usersid: this.form.users_otherid,
        userstype: this.form.users_type,
        usersmarketingtype: this.form.users_maketingtype,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListDashboard"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              feedback.forEach((item) => {
                switch (item.dashboard_status) {
                  case "Q":
                    this.total.quotation = item.dashboard_total;
                    this.total.quotation_amount =
                      this.$functions.NewFormatNumber(item.dashboard_amount);
                    break;
                  case "B":
                    this.total.binding = item.dashboard_total;
                    this.total.binding_amount = this.$functions.NewFormatNumber(
                      item.dashboard_amount
                    );
                    break;
                  case "L":
                    this.total.lossdata = item.dashboard_total;
                    this.total.lossdata_amount =
                      this.$functions.NewFormatNumber(item.dashboard_amount);
                    break;
                }
              });
            } else {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            }
          } else {
            this.SnackBar(true, "error", "No Data Available", 0);
          }
        });
    },
  },
};
</script>
