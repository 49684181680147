<template>
  <div>
    <v-dialog
      v-model="dialogsearch"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-btn icon dark @click.stop="SearchClose()">
            <v-icon>{{ $vars.V("icon_back") }}</v-icon>
          </v-btn>
          <v-toolbar-title>{{ search_title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn outlined @click="SearchGet">
            Search Data
            <v-icon>{{ $vars.V("icon_search") }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container grid-list-md class="pa-12">
          <v-layout row wrap v-if="search_title === 'claim'">
            <template>
              <v-flex xs4>
                <v-text-field
                  v-model="claim.no"
                  id="claim_no"
                  prepend-inner-icon="list_alt"
                  label="Claim No"
                  autofocus
                  @keyup.enter="SearchGet"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  v-model="claim.policyno"
                  id="claim_policyno"
                  prepend-inner-icon="list_alt"
                  label="Certificate No"
                  @keyup.enter="SearchGet"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  v-model="claim.refno"
                  id="claim_refno"
                  prepend-inner-icon="list_alt"
                  label="Reference No"
                  @keyup.enter="SearchGet"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="claim.reportname"
                  id="claim_reportname"
                  prepend-inner-icon="person"
                  label="Report Name"
                  @keyup.enter="SearchGet"
                >
                </v-text-field>
              </v-flex>
            </template>
            <v-flex xs12>
              <v-data-table
                :headers="header[search_title]"
                :items="items[search_title]"
                :loading="loading.claim"
                loading-text="Loading... Please wait"
                dense
                item-key="dataid"
                class="elevation-1 row-pointer"
              >
                <template slot="headerCell" slot-scope="props">
                  <v-tooltip bottom>
                    <span slot="activator">
                      {{ props.header.text }}
                    </span>
                    <span>
                      {{ props.header.text }}
                    </span>
                  </v-tooltip>
                </template>
                <template v-slot:no-data> No Data Available </template>
                <template v-slot:item.action="{ item }">
                  <small
                    ><u @click="CBGet(item.claim_row)" class="cursor"
                      >edit</u
                    ></small
                  >
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
          <v-layout row wrap v-else-if="search_title === 'policy'">
            <template>
              <v-flex xs4>
                <v-text-field
                  v-model="policy.no"
                  id="policy_no"
                  prepend-inner-icon="list_alt"
                  label="Certificate No"
                  autofocus
                  @keyup.enter="SearchGet"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  v-model="policy.certificate"
                  id="policy_certificateno"
                  prepend-inner-icon="list_alt"
                  label="No"
                  @keyup.enter="SearchGet"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  v-model="policy.refno"
                  id="policy_refno"
                  prepend-inner-icon="list_alt"
                  label="Reference No"
                  @keyup.enter="SearchGet"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="policy.insured"
                  id="policy_insured"
                  prepend-inner-icon="person"
                  label="Insured Name"
                  @keyup.enter="SearchGet"
                >
                </v-text-field>
              </v-flex>
            </template>
            <v-flex xs12>
              <v-data-table
                :headers="header[search_title]"
                :items="items[search_title]"
                :loading="loading.policy"
                loading-text="Loading... Please wait"
                dense
                item-key="dataid"
                class="elevation-1 row-pointer"
              >
                <template slot="headerCell" slot-scope="props">
                  <v-tooltip bottom>
                    <span slot="activator">
                      {{ props.header.text }}
                    </span>
                    <span>
                      {{ props.header.text }}
                    </span>
                  </v-tooltip>
                </template>

                <template v-slot:no-data> No Data Available </template>
                <template v-slot:item.action="{ item }">
                  <small
                    ><u @click="CBGet(item.policy_row)" class="cursor"
                      >edit</u
                    ></small
                  >
                </template>
                <template v-slot:item.policy_campaign="{ item }">
                  {{ item.policy_campaign }} - {{ item.policy_campaign_desc }}
                </template>
                <template v-slot:item.policy_subclass="{ item }">
                  {{ item.policy_subclass }} - {{ item.policy_subclass_desc }}
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
          <v-layout row wrap v-else-if="search_title === 'quotation'">
            <template>
              <v-col cols="12" md="6" class="mt-n3">
                <v-text-field
                  v-model="quotation.id"
                  id="ticket_id"
                  prepend-inner-icon="list_alt"
                  label="ID"
                  autofocus
                  @keyup.enter="SearchGet"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <app-cb
                  cb_type="campaign"
                  cb_url="apiListCampaign"
                  cb_url_parameter="campaig_actived=Y"
                  cb_title="Campaign"
                  cb_id="ticket_campaignid"
                  cb_desc="ticket_campaigndesc"
                  cb_rules="Please fill Campaign ID"
                  cb_desc_readonly="readonly"
                  cb_items_id="campaign_id"
                  cb_items_desc="campaign_desc"
                  cb_items_additional_1="campaign_subclass"
                  :cb_value_id="quotation.ticket_campaignid"
                  :cb_value_desc="quotation.ticket_campaigndesc"
                  @clicked="GetCampaign"
                >
                </app-cb>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <app-cb
                  cb_type="cedant"
                  cb_url="apiListProfile"
                  cb_url_parameter="profile_actived=Y"
                  cb_title="Cedant"
                  cb_id="profile_id"
                  cb_desc="profile_name"
                  cb_rules="Please fill Cedant ID"
                  cb_desc_readonly="readonly"
                  cb_items_id="profile_id"
                  cb_items_desc="profile_name"
                  :cb_value_id="quotation.ticket_profileid"
                  :cb_value_desc="quotation.ticket_profiledesc"
                  @clicked="GetCedant"
                >
                </app-cb>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <v-dialog
                  ref="dialog_sdate"
                  v-model="modal.sdate"
                  :return-value.sync="quotation.ticket_sdate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="quotation.ticket_sdate"
                      label="Start Date"
                      prepend-inner-icon="event"
                      background-color="date"
                      v-on="on"
                      v-mask="'####-##-##'"
                      placeholder="YYYY-MM-DD"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="quotation.ticket_sdate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal.sdate = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog_sdate.save(quotation.ticket_sdate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <v-dialog
                  ref="dialog_edate"
                  v-model="modal.edate"
                  :return-value.sync="quotation.ticket_edate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="quotation.ticket_edate"
                      label="End Date"
                      prepend-inner-icon="event"
                      background-color="date"
                      v-on="on"
                      v-mask="'####-##-##'"
                      placeholder="YYYY-MM-DD"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="quotation.ticket_edate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal.edate = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog_edate.save(quotation.ticket_edate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <app-cb
                  cb_type="code"
                  cb_url="apiListCode"
                  cb_url_parameter="code_actived=Y&code_group=TICKETKEY"
                  cb_title="Status"
                  cb_id="ticket_key"
                  cb_desc="ticket_keydesc"
                  cb_rules="Please fill Status"
                  cb_desc_readonly="readonly"
                  cb_items_id="code_id"
                  cb_items_desc="code_desc"
                  :cb_value_id="quotation.ticket_key"
                  :cb_value_desc="quotation.ticket_keydesc"
                  @clicked="GetStatus"
                >
                </app-cb>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <app-cb
                  cb_type="subclass"
                  cb_url="apiListSubClass"
                  cb_url_parameter="subclass_actived=Y"
                  cb_title="Subclass"
                  cb_id="campaign_subclass"
                  cb_desc="campaign_subclass_desc"
                  cb_rules="Please fill Subclass"
                  cb_desc_readonly="readonly"
                  cb_items_id="subclass_id"
                  cb_items_desc="subclass_desc"
                  :cb_value_id="quotation.subclass"
                  :cb_value_desc="quotation.subclassdesc"
                  cb_items_additional_1="subclass_ujroh"
                  @clicked="GetSubClass"
                >
                </app-cb>
              </v-col>
            </template>
            <v-flex xs12>
              <v-data-table
                :headers="header[search_title]"
                :items="items[search_title]"
                :loading="loading.quotation"
                loading-text="Loading... Please wait"
                dense
                item-key="dataid"
                class="elevation-1 row-pointer"
              >
                <template slot="headerCell" slot-scope="props">
                  <v-tooltip bottom>
                    <span slot="activator">
                      {{ props.header.text }}
                    </span>
                    <span>
                      {{ props.header.text }}
                    </span>
                  </v-tooltip>
                </template>
                <template v-slot:no-data> No Data Available </template>
                <template v-slot:item.ticket_id="{ item }">
                  {{ item.ticket_id }}
                </template>

                <template v-slot:item.ticket_campaignid="{ item }">
                  {{ item.ticket_campaignid }} - {{ item.ticket_campaigndesc }}
                </template>

                <template v-slot:item.ticket_subclass="{ item }">
                  {{ item.ticket_subclass }} - {{ item.ticket_subclassdesc }}
                </template>

                <template v-slot:item.ticket_profileid="{ item }">
                  {{ item.ticket_profileid }} - {{ item.ticket_profiledesc }}
                </template>
                <template v-slot:item.action="{ item }">
                  <div
                    @click="CBGet(item.ticket_id)"
                    class="cursor primary--text"
                  >
                    <small><u>Edit</u></small>
                  </div>
                  <div
                    @click="CBTodo(item.ticket_id)"
                    class="cursor secondary--text"
                  >
                    <small
                      ><u>Follow Up ({{ item.ticket_todocount }})</u></small
                    >
                  </div>
                  <div
                    @click="CBPrint(item.ticket_id, item.ticket_quotype)"
                    class="cursor primary--text"
                  >
                    <small><u>Print</u></small>
                  </div>
                </template>
                <!-- <template v-slot:item.ticket_cdate="{ item }">
                  
                </template> -->
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
    <v-snackbar
      :value="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    search_title: String,
    src_id: String,
  },
  data: () => ({
    limit: "1000",
    header: {
      policy: [
        { text: "Action", value: "action" },
        {
          text: "Row",
          value: "policy_row",
          align: "left",
        },
        { text: "Certificate No", value: "policy_no" },
        { text: "No", value: "policy_certificateno" },
        { text: "Reference No", value: "policy_refno" },
        { text: "Insured", value: "policy_longinsured" },
        { text: "Campaign", value: "policy_campaign" },
        { text: "Sub Class", value: "policy_subclass" },
      ],
      claim: [
        { text: "Action", value: "action" },
        {
          text: "Row",
          value: "claim_row",
          align: "left",
        },
        { text: "Claim No", value: "claim_no" },
        { text: "Certificate No", value: "claim_policyno" },
        { text: "Number No", value: "claim_certificateno" },
        { text: "Reference No", value: "claim_refno" },
        { text: "Insured", value: "claim_longinsured" },
      ],
      quotation: [
        { text: "Actions", value: "action", sortable: false, width: "100" },
        {
          text: "ID",
          value: "ticket_id",
          align: "left",
        },
        { text: "Campaign", value: "ticket_campaignid" },
        { text: "Sub Class", value: "ticket_subclass" },
        { text: "Cedant", value: "ticket_profileid" },
        { text: "Transaction Date", value: "ticket_cdate" },
        { text: "Marketing", value: "ticket_mo" },
        { text: "Status", value: "ticket_keydesc" },
      ],
    },
    items: [],
    loading: {
      policy: false,
      claim: false,
      quotation: false,
    },
    claim: {
      no: "",
      policyno: "",
      refno: "",
      reportname: "",
    },
    policy: {
      no: "",
      certificate: "",
      refno: "",
      insured: "",
    },
    quotation: {
      id: "",
      name: "",
      subclass: "",
      subclassdesc: "",
      quotype: "",
      printid: "",
      refid: "",
      ticket_campaignid: "",
      ticket_campaigndesc: "",
      ticket_profileid: "",
      ticket_profiledesc: "",
      ticket_sdate: "",
      ticket_edate: "",
      ticket_status: "",
      ticket_statusdesc: "",
      ticket_key: "",
      ticket_keydesc: "",
      ticket_subclass: "",
      ticket_subclassdesc: "",
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    modal: {
      print: false,
    },
  }),
  //   created() {

  //   },
  computed: {
    dialogsearch: {
      get() {
        return this.dialog;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    SearchClose() {
      this.dialogsearch = false;
    },
    SearchGet() {
      if (this.search_title === "policy") {
        this.loading.policy = true;
        let formdata = {
          policy_no: this.policy.no,
          policy_certificateno: this.policy.certificate,
          policy_refno: this.policy.refno,
          policy_insured: this.policy.insured,
          order_by: "policy_row",
          order_type: "ASC",
          limit: this.limit,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListPolicy"), param, {
            headers: {
              "Content-Type": "text/plain charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback === "Y") {
                this.loading.policy = false;
                this.items[this.search_title] = feedback;
              } else {
                this.loading.policy = false;
                this.SnackBar(true, "error", feedback[0].feedback, 0);
              }
            } else {
              this.loading.policy = false;
              this.SnackBar(true, "error", "No Data Available", 0);
            }
          });
      } else if (this.search_title === "claim") {
        this.loading.claim = true;
        let formdata = {
          claim_no: this.claim.no,
          claim_policyno: this.claim.policyno,
          claim_refno: this.claim.refno,
          claim_reportname: this.claim.reportname,
          order_by: "claim_row",
          order_type: "ASC",
          limit: this.limit,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListClaim"), param, {
            headers: {
              "Content-Type": "text/plain charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback === "Y") {
                this.loading.claim = false;
                this.items[this.search_title] = feedback;
              } else {
                this.loading.claim = false;
                this.SnackBar(true, "error", feedback[0].feedback, 0);
              }
            } else {
              this.loading.claim = false;
              this.SnackBar(true, "error", "No Data Available", 0);
            }
          });
      } else if (this.search_title === "quotation") {
        this.loading.quotation = true;
        let formdata = {
          ticket_id: this.quotation.id,
          ticket_profileid: this.quotation.ticket_profileid,
          ticket_name: this.quotation.name,
          ticket_key: this.quotation.ticket_key,
          ticket_subclass: this.quotation.subclass,
          ticket_quotype: this.quotation.quotype,
          ticket_marketingtype: this.$functions.UsersMarketingType(),
          order_by: "ticket_id",
          order_type: "ASC",
          limit: this.limit,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListTicket"), param, {
            headers: {
              "Content-Type": "text/plain charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback === "Y") {
                this.loading.quotation = false;
                this.items[this.search_title] = feedback;
              } else {
                this.items[this.search_title] = feedback;
                this.loading.quotation = false;
                this.SnackBar(true, "error", feedback[0].feedback, 0);
              }
            } else {
              this.items[this.search_title] = feedback;
              this.loading.quotation = false;
              this.SnackBar(true, "error", "No Data Available", 0);
            }
          });
      }
    },
    CBGet(value) {
      this.$emit("clicked", value);
      this.SearchClose();
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    GetCampaign(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let campaignid = splits[0];
        let campaign_desc = splits[1];
        let campaign_subclass = splits[3];
        this.quotation.ticket_campaignid = campaignid;
        this.quotation.ticket_campaigndesc = campaign_desc;
        this.quotation.ticket_subclass = campaign_subclass;
      }
    },
    GetCedant(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let profileid = splits[0];
        let profiledesc = splits[1];
        this.quotation.ticket_profileid = profileid;
        this.quotation.ticket_profiledesc = profiledesc;
      }
    },
    GetStatus(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let code_id = splits[0];
        let code_desc = splits[1];
        this.quotation.ticket_key = code_id;
        this.quotation.ticket_keydesc = code_desc;
      }
    },
    GetMarketing(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let mo = splits[0];
        let modesc = splits[1];
        this.form.ticket_mo = mo;
        this.form.ticket_modesc = modesc;
      }
    },
    GetSubClass(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        this.quotation.subclass = splits[0];
        this.quotation.subclassdesc = splits[1];
      }
    },
  },
};
</script>
