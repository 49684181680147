import * as api from '../configs/config.json'
import * as packages from '../../package.json'
export default {
    // Version
    AppVersion() {
        const appversion = packages.version
        return appversion
    },
    AppTitle() {
        const apptitle = api.app_title
        return apptitle
    },
    AppDoc() {
        const apptitle = api.api_doc
        return apptitle
    },
    //UsersID get local storage users id
    UsersID() {
        let usersid = localStorage.getItem('local_core_users')
        return usersid
    },
    //UsersID get local storage users id
    CheckLogin() {
        let login = localStorage.getItem('local_core_login')
        return login
    },
    UsersType() {
        let usersdetail = JSON.parse(localStorage.getItem('local_core_users_detail'))
        let userstype = usersdetail[0].users_type
        return userstype
    },
    UsersName() {
        let usersdetail = JSON.parse(localStorage.getItem('local_core_users_detail'))
        let usersname = usersdetail[0].users_name
        return usersname
    },
    UsersOthersID() {
        let usersdetail = JSON.parse(localStorage.getItem('local_core_users_detail'))
        let usersothersid = usersdetail[0].users_otherid
        return usersothersid
    },
    UsersMarketingType() {
        let usersdetail = JSON.parse(localStorage.getItem('local_core_users_detail'))
        let usersmarketingtype = usersdetail[0].users_marketingtype
        return usersmarketingtype
    },
    UsersCompany() {
        let usersdetail = JSON.parse(localStorage.getItem('local_core_users_detail'))
        let users_company = usersdetail[0].users_company
        return users_company
    },
    //SafeURL curl json api
    SafeURL(url, params) {
        const hosts = api.api_connection
        const keys = api.api_key
        const secret = api.api_secret
        const loginuser = this.UsersID()
        let newurl
        if (params !== '') {
            newurl = `${hosts}/${url}?key=${keys}&secret=${secret}&${params}&login_user=${loginuser}`
        } else {
            newurl = `${hosts}/${url}?key=${keys}&secret=${secret}&login_user=${loginuser}`
        }
        return newurl
    },
    DocumentURL(file, params, convert) {
        const hosts = "http://localhost"
        const folder = "PHPDocument"
        const subfolder = "views"
        const modul = "modul"
        let submodul
        if (convert == 'pdf') {
            submodul = 'mod_pdf'
        } else {
            submodul = 'mod_word'
        }
        let newfile
        file = file.replace(/\s/g, '_')
        newfile = file.toLowerCase()
        let newurl
        if (params !== '') {
            newurl = `${hosts}/${folder}/${subfolder}/${modul}/${submodul}/${newfile}.php?${params}`
        } else {
            newurl = `${hosts}/${folder}/${subfolder}/${modul}/${submodul}/${newfile}.php`
        }
        return newurl
    },
    TrueOrFalse(flag) {
        let returns = false
        if (flag === 'Y') {
            returns = true
        }
        return returns
    },
    FCode(flag) {
        let returns = false
        if (flag === 'D') {
            returns = true
        }
        return returns
    },
    Access(flag) {
        let returns = false
        if (flag === 1) {
            returns = true
        }
        return returns
    },
    Method(flag) {
        let returns = false
        if (flag === 'POST') {
            returns = true
        }
        return returns
    },
    Replacer(datas) {
        const replacedatas = datas.replace(/&/g, '<br>')
        return replacedatas
    },
    FeedbackReplacer(datas) {
        const replacedatas = datas.replace(/,/g, '<br>')
        return replacedatas
    },
    Gender(S) {
        let returns = true
        if (S === 'F') {
            returns = false
        }
        return returns
    },
    RiskCategory(flag) {
        let returns = false
        if (flag === 'M') {
            returns = true
        }
        return returns
    },
    RateUnit(flag) {
        let returns = 'Cent'
        if (flag === 'M') {
            returns = 'Mile'
        } else if (flag === 'F') {
            returns = 'Flat'
        }
        return returns
    },
    //FormatDateTime format date MM/DD/YYYY H:i:s
    FormatDateTimeSession(dates) {
        let today = new Date(dates)
        let dd = today.getDate()
        let mm = today.getMonth() + 1
        let yyyy = today.getFullYear()
        let hh = today.getHours()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        if (hh < 10) {
            hh = '0' + hh
        }
        today = dd + '-' + mm + '-' + yyyy
        return today
    },
    //Format New Date
    FormatNewDate(dates) {
        let today = new Date(dates)
        let dd = today.getDate()
        let mm = today.getMonth() + 1
        let yyyy = today.getFullYear()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = yyyy + '-' + mm + '-' + dd
        return today
    },
    Level(L) {
        let returns = 'H'
        if (L === 'L') {
            returns === 'L'
        }
        return returns
    },
    UrlJWT(url) {
        const hosts = this.AppConnection()
        let newurl = `${hosts}/${url}`
        return newurl
    },
    ParamJWT(param) {
        let key = api.api_key
        let secret = api.api_secret
        let login_user = this.UsersID()
        let data = {
            key: key,
            secret: secret,
            login_user: login_user,
        }
        let combine = Object.assign(data, param)
        return combine
    },
    AppConnection() {
        let host = api.api_connection
        return host
    },
    YesOrNo(flag) {
        let returns = 0
        if (flag === 'Y') {
            returns = 1
        }
        return returns
    },
    FieldType(flag) {
        let returns = 0
        if (flag === 'N') {
            returns = 1
        } else if (flag === 'D') {
            returns = 2
        }
        return returns
    },
    TSISI(flag) {
        let returns = 0
        if (flag === 'SI') {
            returns = 1
        }
        return returns
    },
    Split(data, delimiter) {
        let split = data.split(delimiter)
        return split
    },
    UrlPOST(url) {
        const hosts = api.api_connection
        let newurl = `${hosts}/${url}`
        return newurl
    },
    ParamPOST(param) {
        let key = api.api_key
        let secret = api.api_secret
        let login_user = this.UsersID()
        var combine = JSON.stringify({ key: key, secret: secret, login_user: login_user, "data": param })
        return combine
    },
    //UploadPOST curl json api
    UploadPOST(url, params) {
        const hosts = api.api_connection
        const keys = api.api_key
        const secret = api.api_secret
        const loginuser = this.UsersID()
        let newurl
        if (params !== '') {
            newurl = `${hosts}/${url}?key=${keys}&secret=${secret}&${params}&login_user=${loginuser}`
        } else {
            newurl = `${hosts}/${url}?key=${keys}&secret=${secret}&login_user=${loginuser}`
        }
        return newurl
    },
    ErrorCode() {
        let returns = "Check Error Message"
        return returns
    },
    NoPriviledge(modul) {
        let returns = "You Dont have access modul `" + modul + "`"
        return returns
    },
    FormatNumber(value, fixed) {
        let newvalue = value.toLocaleString(undefined, {
            minimumFractionDigits: fixed,
            maximumFractionDigits: fixed
        })
        return newvalue
    },
    TodayYYYYMMDD() {
        let today = new Date()
        let dd = today.getDate()
        let mm = today.getMonth() + 1
        let yyyy = today.getFullYear()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = yyyy + '-' + mm + '-' + dd
        return today
    },
    Checked(flag) {
        let returns = 0
        if (flag === true) {
            returns = 1
        }
        return returns
    },
    ActivedFlag(flag) {
        let returns = 'N'
        if (flag === true) {
            returns = 'Y'
        }
        return returns
    },
    NoData() {
        let returns = "No Data Available"
        return returns
    },
    CentMil(flag) {
        let returns = 'Flat'
        if (flag === 'M') {
            returns = 'Mile'
        } else if (flag === 'C') {
            returns = 'Cent'
        }
        return returns
    },
    SwitchFlag(flag) {
        let returns = 'N'
        if (flag === 1) {
            returns = 'Y'
        }
        return returns
    },
    //FormatDateFull format date MM/DD/YYYY
    FormatDateFullName(date) {
        if (!date) return null
        if (date === '1900-01-01') return null
        const dates = date
        const [year, month, day] = dates.split('-')
        const newmonth = this.ShortMonthNameEng(month)
        return `${day} ${newmonth} ${year}`
    },
    //ShortMonthNameEng MonthNameEnglish
    ShortMonthNameEng(months) {
        let monthsindo = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        let getmonth = monthsindo[parseInt(months) - 1]
        return getmonth
    },
    //MonthNameEng MonthNameEnglish
    MonthNameEng(months) {
        let monthsindo = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        let getmonth = monthsindo[parseInt(months) - 1]
        return getmonth
    },
    NewFormatNumber(value, fixed) {
        let val = (value / 1).toFixed(fixed).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    Variable(flag, position) {
        let data = []
        if (flag === 'confirm') {
            data = [390, 'success', 'error', 'primary white--text', 'white']
        }
        return data[position]
    },
    AttachmentIcon(icon) {
        let returns = ''
        if (icon === 'pdf') {
            returns = 'mdi-file-pdf-box'
        } else if (icon === 'png') {
            returns = 'mdi-file-png-box'
        } else if (icon === 'jpg' || icon === 'jpeg') {
            returns = 'mdi-file-jpg-box'
        } else if (icon === 'xls' || icon === 'xlsx') {
            returns = 'mdi-file-excel-box'
        } else if (icon === 'doc' || icon === 'docs') {
            returns = 'mdi-file-word-box'
        }
        return returns
    },
    OpenURL(url) {
        window.open(url, 'targetWindow',
            `toolbar=no,
        location=no,
        status=no,
        menubar=no,
        scrollbars=yes,
        resizable=yes,
        width=SomeSize,
        height=SomeSize`)
    },
    //FormatDateTime format date MM/DD/YYYY H:i:s
    FormatDateTime(date) {
        if (!date) return null
        const [dates, times] = date.split('T')
        const [year, month, day] = dates.split('-')
        const newtime = times.split('.')
        const splittime = newtime[0].split(':')
        const newmonth = this.ShortMonthNameEng(month)
        return `${newmonth} ${day} ${year} ${splittime[0]}:${splittime[1]}`
    },
    //FormatDateFull format date MM/DD/YYYY H:i:s
    FormatDateFull(date) {
        if (!date) return null
        const dates = date.split('T')
        const [year, month, day] = dates[0].split('-')
        const newmonth = this.ShortMonthNameEng(month)
        return `${newmonth} ${day} ${year}`
    },
    ReplaceNumber(number) {
        return number.replaceAll(".", "")
    },
    CompareIcon(data1, data2) {
        var returns = 'arrow_downward'
        if (data1 > data2) {
            returns = 'arrow_upward'
        }
        return returns
    },
    ReadMore(data, split) {
        let notes = data
        if (data.length > split) {
            notes = data.substring(0, split) + '<strong><i>...lebih lanjut</i></strong>'
        }
        return notes
    },
    RateUnitPCT(flag) {
        let returns = '%'
        if (flag === '%o') {
            returns = 'Mile'
        } else if (flag === 'F') {
            returns = ''
        }
        return returns
    },
    PeriodUnit(flag) {
        let returns = 'Day'
        if (flag === 'M') {
            returns = 'Month'
        } else if (flag === '') {
            returns = 'Year'
        }
        return returns
    },
    EncodeUrl(value) {
        let returns = value
        if (value === undefined) {
            returns = ''
        }
        let today = this.TodayYYYYMMDD()
        let login_user = this.UsersID()
        let encode = btoa(login_user + today + '&' + returns)

        return encode
    },
    DecodeUrl(value) {
        let returns = value
        if (value === undefined) {
            returns = ''
        }
        let decode = atob(returns)
        let split = decode.split('&')
        let id = split[1]
        return id
    },
    FormatTime(time) {
        if (time === '00:00') return null
        return time
    },
    FormatDate(date) {
        if (date === '1900-01-01') return null
        return date
    },
    TimeDiff(dateString1, dateString2) {
        if (dateString1 === '1900-01-01T00:00:00Z') return null
        if (dateString2 === '1900-01-01T00:00:00Z') return null
        // Konversi string tanggal menjadi objek Date
        const date1 = new Date(dateString1)
        const date2 = new Date(dateString2)
    
        // Hitung selisih waktu dalam milidetik
        const diff = Math.abs(date2 - date1)
    
        // Hitung jam, menit, detik
        let milliseconds = diff
        const hours = Math.floor(milliseconds / (1000 * 60 * 60))
        milliseconds -= hours * (1000 * 60 * 60)
        const minutes = Math.floor(milliseconds / (1000 * 60))
        milliseconds -= minutes * (1000 * 60)
        const seconds = Math.floor(milliseconds / 1000)
        
        let hourstring = hours+' h'
        let minutestring = ' '+minutes+' m'
        let secondstring = ' '+seconds+' s'
        if (hours === 0) {
            hourstring = ''
        }
        if (minutes === 0) {
            minutestring = ''
        }
        if (seconds === 0) {
            secondstring = ''
        }
        let result = hourstring+''+minutestring+''+secondstring
        return result
    },
    CreateAnchorTags(urls) {
    let result = ''
    if (urls !== '') {
        // Pisahkan URLs menjadi array
        const urlArray = urls.split('|');
        let arraylen = urlArray.length
        if (arraylen > 0){
            // Membuat string HTML untuk setiap URL
            const anchorTags = urlArray.map((url, index) => {
                const fileName = url.substring(url.lastIndexOf('/') + 1); // Ambil bagian nama file dari URL
                // return `<a href='${url}' target='_blank'>${fileName}</a>`;
                return `<a href='${url}' target='_blank' title='${fileName}'>File ${index + 1}</a>`;
            });
            
            // Menggabungkan semua tag <a> menjadi satu string
            result = anchorTags.join('<br>'); // Ganti '<br>' dengan karakter pemisah yang diinginkan
        }
    }
    
    return result;
    }
}