<template>
  <div id="app-reinsuranse">
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <div v-if="access.read === 0" class="text-center font-weight-black">
      {{ $vars.V("txt_no_access") }}
    </div>
    <div v-else>
      <v-card-title class="ml-n4">
        <v-btn
          :color="selected.length === items.length ? 'fourth' : ''"
          @click="SelectAllPage"
          class="mr-2"
          title="select all page"
          small
          >{{ $vars.V("txt_select_all_page") }}
        </v-btn>
        <v-btn
          color="error"
          title="delete selected"
          @click="Confirm('multidelete', '')"
          class="mr-2"
          v-if="selected.length > 0"
          :disabled="access.delete === 0 ? true : false"
          small
        >
          <v-icon>{{ $vars.V("icon_delete") }}</v-icon>
        </v-btn>
        <v-btn @click="Add" class="mr-2" title="Add New" small
          >{{ $vars.V("txt_add") }}
        </v-btn>
        <v-btn @click="List(id)" class="mr-2" title="Refresh" small
          ><v-icon>{{ $vars.V("icon_refresh") }}</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          title="search"
          v-if="btn_search === false"
          @click="btn_search = true"
          small
        >
          <v-icon>{{ $vars.V("icon_search") }}</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          @click:append="btn_search = false"
          label="Search"
          single-line
          hide-details
          v-if="btn_search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :value="selected"
        :headers="headers"
        :items="items"
        :search="search"
        @input="Check($event)"
        item-key="reinstrans_profileid"
        show-select
        class="elevation-1"
        :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right',
        }"
        dense
        :loading="loading.detail"
        loading-text="Loading... Please wait"
      >
        <!-- <template v-slot:item.reinstrans_ricomm="{ item }">
          {{ $functions.NewFormatNumber(item.reinstrans_ricomm) }}
        </template> -->
        <template v-slot:item.reinstrans_ricomm="{ item }">
          {{ $functions.NewFormatNumber(item.reinstrans_ricomm, 15) }}
        </template>
        <template v-slot:item.reinstrans_finalshare="{ item }">
          {{ $functions.NewFormatNumber(item.reinstrans_finalshare, 15) }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-row class="align-center">
            <v-col class="d-flex align-center">
              <v-icon
                @click="Edit(item.reinstrans_docno, item.reinstrans_profileid)"
                :disabled="access.edit === 0 ? true : false"
                title="edit"
                color="info"
                class="mx-2"
              >
                {{ $vars.V("icon_edit") }}
              </v-icon>
              <div
                @click="
                  Send(
                    item.reinstrans_docno,
                    item.reinstrans_profileid,
                    item.reinstrans_profiledesc,
                    item.reinstrans_pic_marketing,
                    item.reinstrans_language
                  )
                "
                :title="'Send'"
                :style="{
                  cursor: access.edit === 0 ? 'not-allowed' : 'pointer',
                }"
                class="icon-wrapper v-icon v-icon--link"
              >
                <svg
                  version="1.1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  xml:space="preserve"
                  class="custom-svg"
                >
                  <path
                    id="paper-plane-icon"
                    d="M462,54.955L355.371,437.187l-135.92-128.842L353.388,167l-179.53,124.074L50,260.973L462,54.955z
          M202.992,332.528v124.517l58.738-67.927L202.992,332.528z"
                  ></path>
                </svg>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </div>
    <!-- Start Form -->
    <v-dialog
      v-model="dialog.detail"
      max-width="800"
      transition="dialog-transition"
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="dialog.detail = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Reinsurance</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="Add" title="Add New"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn icon dark @click="Confirm(url_type, '')" title="Save"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-form
            enctype="multipart/form-data"
            ref="form_organization"
            lazy-validation
          >
            <v-row class="mx-auto">
              <v-col cols="12" sm="12" md="12" class="mt-3">
                <app-cb
                  cb_type="profile"
                  cb_url="apiListProfile"
                  cb_url_parameter="profile_lob=06&profile_actived=Y"
                  cb_title="Reinsurance Name"
                  cb_id="reinstrans_profileid"
                  cb_desc="reinstrans_profiledesc"
                  cb_rules="Reinsurance ID"
                  cb_desc_readonly="readonly"
                  cb_items_id="profile_id"
                  cb_items_desc="profile_fullname"
                  cb_items_additional_1="profile_address"
                  :cb_value_id="form.reinstrans_profileid"
                  :cb_value_desc="form.reinstrans_profiledesc"
                  @clicked="GetProfile"
                >
                </app-cb>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <vuetify-money
                  v-model="form.reinstrans_share"
                  v-bind:options="formatrate"
                  label="Estimate Share"
                  placeholder="Estimate Share"
                  background-color="numeric"
                  v-bind:properties="properties"
                />
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <vuetify-money
                  v-model="form.reinstrans_ricomm"
                  v-bind:options="format_pct"
                  label="RI COM"
                  placeholder="RI COM"
                  background-color="numeric"
                  v-bind:properties="properties"
                />
              </v-col>
              <v-col cols="12" sm="5" md="5">
                <vuetify-money
                  v-model="form.reinstrans_finalshare"
                  v-bind:options="formatamount15"
                  label="Final Share"
                  placeholder="Final Share"
                  background-color="readonly"
                  v-bind:properties="properties"
                  readonly
                />
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <vuetify-money
                  v-model="form.reinstrans_reminderdays"
                  v-bind:options="formatamount"
                  label="Reminder Days"
                  placeholder=""
                  background-color="numeric"
                  v-bind:properties="properties"
                  :readonly="true"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-dialog
      v-model="confirm.dialog"
      :max-width="Variable('confirm', 0)"
      persistent
    >
      <v-card :color="Variable('confirm', 4)">
        <v-card-title :class="Variable('confirm', 3)"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            :color="Variable('confirm', 1)"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
          >
            {{ confirm.text }}
          </v-btn>
          <v-btn :color="Variable('confirm', 2)" @click="confirm.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Reinsuranse -->
    <v-dialog
      v-model="confirm.attachment"
      scrollable
      max-width="1200"
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="confirm.attachment = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>{{ confirm.attachmenttitle }}?</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="Confirm('sendemail')"
            color="red"
            small
            style="color: white"
          >
            SEND
            <svg
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              xml:space="preserve"
              style="width: 20px; height: 20px; margin-left: 5px; fill: white"
            >
              <path
                id="paper-plane-icon"
                d="M462,54.955L355.371,437.187l-135.92-128.842L353.388,167l-179.53,124.074L50,260.973L462,54.955z
      M202.992,332.528v124.517l58.738-67.927L202.992,332.528z"
              ></path>
            </svg>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <app-attachmentsend
            :value="true"
            :refno="form.reinstrans_docno"
            :profileid="form.reinstrans_profileid"
            :profiledesc="form.reinstrans_profiledesc"
            :picmarketing="form.reinstrans_pic_marketing"
            :subclass="subclass"
            :quolang="form.reinstrans_quo_lang"
            @selected-changed="handleSelectedChanged"
            @update:reinstrans_template="updateReinstransTemplate"
            @update:reinsuranse_pic_marketing="updateReinsuransePicMarketing"
            @update:reinsuranse_pic_marketing_cc="
              updateReinsuransePicMarketingCC
            "
            @update:language="updateLanguage"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Reinsuranse -->

    <v-dialog v-model="confirm.send" max-width="490" persistent>
      <v-card>
        <v-card-title class="tertiary white--text">
          {{ confirm.sendtitle }}?</v-card-title
        >
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="success"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
            >{{ confirm.text }}
          </v-btn>
          <v-btn color="error" @click="confirm.send = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: Boolean,
    id: String,
    subclass: String,
  },
  data: () => ({
    url_type: "add",
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    remarks: {
      feedback: "",
      dialog: false,
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
      attachment: false,
      send: false,
      sendtitle: "",
      attachmenttitle: "",
    },
    loading: {
      page: true,
      detail: true,
    },
    dialog: {
      detail: false,
    },
    formatamount15: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 30,
      precision: 15,
    },
    formatamount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0,
    },
    formatrate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 5,
      precision: 2,
    },
    format_pct: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 18,
      precision: 15,
    },
    properties: {
      reverse: true,
    },
    selected: [],
    selectedattach: [],
    items: [],
    btn_search: false,
    search: "",
    headers: [
      { text: "Docno", value: "reinstrans_docno" },
      { text: "Name", value: "reinstrans_profiledesc" },
      {
        text: "Estimate Share",
        value: "reinstrans_share",
        align: "right",
      },
      {
        text: "RI COM",
        value: "reinstrans_ricomm",
        align: "right",
      },
      {
        text: "Final Share",
        value: "reinstrans_finalshare",
        align: "right",
      },
      {
        text: "Reminder Days",
        value: "reinstrans_reminderdays",
        align: "right",
      },
      {
        text: "Status",
        value: "reinstrans_status",
        align: "center",
      },
      { text: "Actions", value: "action", sortable: false },
    ],
    form: {
      reinstrans_docno: "",
      reinstrans_profileold: "",
      reinstrans_profileid: "",
      reinstrans_profiledesc: "",
      reinstrans_type: "",
      reinstrans_share: 0,
      reinstrans_ricomm: 0,
      reinstrans_finalshare: 0,
      reinstrans_reminderdays: 0,
      reinstrans_status: "",
      reinstrans_actived: "",
      reinstrans_pic_marketing: "",
      reinstrans_pic_marketing_cc: "",
      reinstrans_quo_lang: "",
      reinstrans_pic_claim: "",
      reinstrans_pic_finance: "",
      reinstrans_template: "",
    },
  }),
  watch: {
    id: function (newdata) {
      if (newdata !== "") {
        this.id = newdata;
        this.List(newdata);
      } else {
        this.id = "";
        this.List(newdata);
      }
    },
  },
  created() {
    this.List(this.id);
    console.log("riva" + this.subclass);
  },
  methods: {
    Access() {
      let modul = "profile";
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data;
          if (priv.length > 0) {
            this.access.read = priv[0].usersmenu_read;
            this.access.add = priv[0].usersmenu_add;
            this.access.edit = priv[0].usersmenu_edit;
            this.access.delete = priv[0].usersmenu_delete;
            if (priv[0].usersmenu_read === 0) {
              this.SnackBar(
                true,
                "error",
                this.$functions.NoPriviledge(modul),
                0
              );
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            );
          }
          this.loading.page = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.loading.page = false;
        });
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    Variable(flag, position) {
      return this.$functions.Variable(flag, position);
    },
    SelectAllPage() {
      this.selected = this.selected === this.items ? [] : this.items;
    },
    Check(value) {
      this.selected = value;
    },
    Loading() {
      this.confirm.text = "Loading...";
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write();
      } else if (this.url_type === "delete") {
        this.Delete(this.form.profileorganization_id);
      } else if (this.url_type === "multidelete") {
        this.MultiProcess("delete");
      } else if (this.url_type === "sendemail") {
        this.SendEmail();
      }
    },

    Write() {
      let reinstrans_docno = this.id;
      let formdata = {
        url_type: this.url_type,
        reinstrans_docno: reinstrans_docno,
        reinstrans_profileold: this.form.reinstrans_profileold,
        reinstrans_profileid: this.form.reinstrans_profileid,
        reinstrans_type: this.form.reinstrans_type,
        reinstrans_share: this.form.reinstrans_share,
        reinstrans_ricomm: String(this.form.reinstrans_ricomm),
        reinstrans_finalshare: String(this.form.reinstrans_finalshare),
        reinstrans_reminderdays: String(this.form.reinstrans_reminderdays),
        reinstrans_status: this.form.reinstrans_status,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteReinsTrans"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.dialog.detail = false;
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.List(this.id);
              this.url_type = "edit";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    MultiProcess() {
      let multiData = JSON.stringify(this.selected);
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteReinsTrans"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0);
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.selected = [];
            this.List(this.id);
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    Delete(id) {
      let formdata = {
        url_type: "delete",
        reinstrans_docno: id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteReinsTrans"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.List(this.id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.confirm.dialog = false;
          this.confirm.text = "Ok";
        });
    },
    Add() {
      this.dialog.detail = true;
      this.url_type = "add";
      this.form.reinstrans_docno = "";
      this.form.reinstrans_profileid = "";
      this.form.reinstrans_profiledesc = "";
      this.form.reinstrans_type = "Q";
      this.form.reinstrans_share = 0;
      this.form.reinstrans_ricomm = 0;
      this.form.reinstrans_finalshare = 0;
      this.form.reinstrans_reminderdays = 0;
      this.form.reinstrans_status = "R";
      this.form.reinstrans_actived = "Y";
      var formdata = {};
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiGetReminderDays"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.form.reinstrans_reminderdays =
                feedback[0].setting_reminderdays;
            } else {
              this.SnackBar(true, "error", feedback[0].feedback, "3000");
            }
          } else {
            this.SnackBar(true, "error", this.$functions.NoData(), "3000");
          }
          this.loading.detail = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.detail = false;
        });
      setTimeout(function () {
        document.getElementById("reinstrans_profileid").focus();
      }, 500);
    },
    Edit(docno, profileid) {
      if (docno !== "" && profileid !== "") {
        this.Add();
        this.loading.detail = true;
        let formdata = {
          reinstrans_docno: docno,
          reinstrans_profileid: profileid,
        };
        this.form.reinstrans_profileold = profileid;
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListReinsTrans"), param, {
            headers: {
              "Content-Type": "text/plain; charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback === "Y") {
                this.dialog.detail = true;
                this.url_type = "edit";
                this.form.reinstrans_docno = feedback[0].reinstrans_docno;
                this.form.reinstrans_profiledesc =
                  feedback[0].reinstrans_profiledesc;
                this.form.reinstrans_profileid =
                  feedback[0].reinstrans_profileid;
                this.form.reinstrans_type = feedback[0].reinstrans_type;
                this.form.reinstrans_share = feedback[0].reinstrans_share;
                this.form.reinstrans_ricomm = feedback[0].reinstrans_ricomm;
                this.form.reinstrans_reminderdays =
                  feedback[0].reinstrans_reminderdays;
                this.form.reinstrans_finalshare =
                  feedback[0].reinstrans_finalshare;
                this.form.reinstrans_status = feedback[0].reinstrans_status;
              } else {
                this.SnackBar(true, "error", feedback[0].feedback, "3000");
              }
            } else {
              this.SnackBar(true, "error", this.$functions.NoData(), "3000");
            }
            this.loading.detail = false;
          })
          .catch((e) => {
            this.SnackBar(true, "error", e, 3000);
            this.loading.detail = false;
          });
      }
    },
    Confirm(flag, id) {
      if (flag === "add" || flag === "edit") {
        if (this.$refs.form_organization.validate()) {
          this.confirm.dialog = true;
          this.confirm.title = "Save";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "delete") {
        this.url_type = "delete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + id + "`";
        this.form.profileorganization_id = id;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multidelete") {
        this.url_type = "multidelete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + this.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "sendemail") {
        this.url_type = "sendemail";
        this.confirm.send = true;
        this.confirm.sendtitle = "Send";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },
    List(id) {
      this.Access();
      this.items = [];
      if (id !== "") {
        this.loading.detail = true;
        this.form.reinstrans_docno = id;
        let formdata = {
          reinstrans_docno: id,
          order_by: "reinstrans_docno",
          order_type: "ASC",
          limit: this.limit,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListReinsTrans"), param, {
            headers: {
              "Content-Type": "text/plain; charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback !== "Y") {
                this.SnackBar(true, "error", feedback[0].feedback, 0);
              } else {
                this.items = feedback;
              }
            } else {
              this.items = feedback;
              // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
            }
            this.loading.detail = false;
          })
          .catch((e) => {
            this.SnackBar(true, "error", e, 3000);
            this.loading.detail = false;
          });
      }
    },
    GetProfile(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        this.form.reinstrans_profileid = splits[0];
        this.form.reinstrans_profiledesc = splits[1];
      }
    },
    handleSelectedChanged(selected) {
      // Update selected data in the parent component
      this.selectedattach = selected;
    },
    updateReinstransTemplate(value) {
      this.form.reinstrans_template = value;
    },
    updateReinsuransePicMarketing(value) {
      if (Array.isArray(value)) {
        const validEmails = value.filter((email) => this.isValidEmail(email));
        this.form.reinstrans_pic_marketing = validEmails.join(",");
      }
    },
    updateReinsuransePicMarketingCC(value) {
      if (Array.isArray(value)) {
        const validEmails = value.filter((email) => this.isValidEmail(email));
        this.form.reinstrans_pic_marketing_cc = validEmails.join(",");
      }
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    updateLanguage(value) {
      this.form.reinstrans_quo_lang = value == "E" ? "E" : "I";
    },
    Send(docno, profileid, profiledesc, pic_marketing, lang) {
      this.form.reinstrans_docno = docno;
      this.form.reinstrans_profileid = profileid;
      this.form.reinstrans_profiledesc = profiledesc;
      this.form.reinstrans_pic_marketing = pic_marketing;
      this.form.reinstrans_quo_lang = lang;
      this.confirm.attachment = true;
      this.confirm.attachmenttitle = "Send To Reinsurance";
    },
    SendEmail() {
      let multiData = JSON.stringify(this.selectedattach);
      const formData = {
        url_type: "sendemail",
        reinstrans_docno: this.form.reinstrans_docno,
        reinstrans_profileid: this.form.reinstrans_profileid,
        reinstrans_profiledesc: this.form.reinstrans_profiledesc,
        reinstrans_pic_marketing: this.form.reinstrans_pic_marketing,
        reinstrans_pic_marketing_cc: this.form.reinstrans_pic_marketing_cc,
        reinstrans_template: this.form.reinstrans_template,
        reinstrans_lang: this.form.reinstrans_quo_lang,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formData);

      this.$axios
        .post(this.$functions.UrlPOST("apiWriteReinsTrans"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
              this.confirm.send = false;
              this.confirm.text = "Ok";
              this.confirm.attachment = false;
            } else {
              this.SnackBar(true, "success", "Success", 3000);
              this.confirm.send = false;
              this.confirm.text = "Ok";
              this.confirm.attachment = false;
            }
          } else {
            this.items = feedback;
            // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
          }
          this.loading.detail = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.detail = false;
        });
    },
  },
};
</script>
<style scoped>
.custom-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px; /* Sesuaikan ukuran sesuai kebutuhan */
  height: 20px; /* Sesuaikan ukuran sesuai kebutuhan */
  background-color: red;
  border-radius: 100%;
  padding: 2px;
}
.icon-wrapper:hover {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.custom-svg {
  width: 15px;
  height: 20px;
  fill: rgb(255, 255, 255);
}
</style>
